//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['value', 'flush'],
    data() {
        return {
            invalid: false,
            content: this.value,
        };
    },
    methods: {
        onInvalid() {
            this.invalid = true;
        },
        onBlur(e) {
            this.$emit('blur', e);
            this.$el.checkValidity();
        },
        handleInput(e) {
            this.$emit('input', e.target.value);
            this.$el.classList.remove('input');
        },
    },
};
